  /*
  Table of contents
    1.0.- Global variables
    2.0.- General tags
    3.0.- Intro section
    4.0.- About Me section
    5.0.- Skills section
    6.0.- Employee Background section
    7.0.- Portfolio section
    8.0.- Contact section
    9.0.- Quizz section
    10.0.- Utilities
      10.1.- Toast
      10.2.- Modals
      10.3.- Slider
      10.4- Snackbar
    11.0.- Animations
  */

/* ============== 1.0.- Global variables  ============== */

$padding-height: 6vh;
$padding-width: 2vw;
$lightgray: steelblue;
$blue: steelblue;
$black: rgba(0,0,0,0.8);
$bounceEasing: cubic-bezier(0.51, 0.92, 0.24, 1.15);
$background-white: radial-gradient(#FFFFFF, #FBFBFB);
$background-blue: linear-gradient(to bottom, #112d4e, #112f52, #113156, #12335b, #12355f);
$big-margin-bottom: 4vh;
$middle-margin-bottom: 2vh;
$small-margin-bottom: 1vh;

/* ============== 2.0.- General tags  ============== */

html, body {
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: calc(13px + (16 - 13) * (100vw - 400px) / (800 - 400));
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth; }


hr,h2,h3,h4,h5, html,body {
  margin: 0; }

h2 {
  font-size: calc(22px + (30 - 22) * (100vw - 400px) / (800 - 400)); }

h3 {
  font-size: calc(15px + (23 - 15) * (100vw - 400px) / (800 - 400)); }

h4 {
  font-size: calc(18px + (20 - 18) * (100vw - 400px) / (800 - 400)); }

li {
  list-style: none; }

section {
  position: relative;
  scroll-snap-align: start;
  border: none; }

p {
  white-space: pre-line; }

textarea {
  font-family: 'Poppins', sans-serif;
  font-size: calc(6px + (9 - 6) * (100vw - 400px) / (800 - 400)); }

.small-text {
  font-size: calc(12px + (10 - 9) * (100vw - 400px) / (800 - 400)); }

.title-section {
  font-size: calc(16px + (28 - 16) * (100vw - 400px) / (800 - 400)); }

.subtitle {
  font-size: calc(14px + (22 - 14) * (100vw - 400px) / (800 - 400)); }

.white-text {
  color: #FFFFFF; }

/* ============== 3.0.- Intro section  ============== */


.intro {
  background-image: linear-gradient(#112E4E, #112D4E, #112D4E, #112D4E,#14365B);
  height: 100vh;
  position: relative;
  color: #fff;
  font-size: calc(28px + (34 - 28) * (100vw - 400px) / (800 - 400));

  &__text-container {
    text-transform: uppercase;
    position: absolute;
    z-index: 3;
    bottom: 5em;
    padding-left: 2.5vw;
    letter-spacing: 0.3vw;
    text-align: center;

    &__name,
    &__title {
      font-weight: 300; }

    &__name {
      font-size: 1em;
      margin: 0; }

    &__title {
      font-size: 0.5em; } }

  &__background {
    height: 100%;
    width: 100%;
    background: url("/main_my_photo_2_4.jpg");
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    z-index: 1;
    opacity: .3;
    filter: grayscale(1); }

  &__svg-container {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -0.10px;
    width: 33vw;

    &__blue-triangle {
      fill: #112D4E; }

    &__white-triangle {
      fill: #FFF;
      stroke: #FFF; } } }

/* ============== 4.0.- About me section  ============== */

.about-me {
  display: grid;
  grid-template-rows: 100vh;

  &__container {
    display: grid;
    grid-template-rows: 50vh 50vh;

    &__summary {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content auto;
      column-gap: 2vw;
      padding: 4vh 4vw;
      align-items: center;
      background: #FFF;

      &__title {
        margin-bottom: $small-margin-bottom; }

      &__description {
        grid-row: 2;
        grid-column: 2;
        text-align: justify; }

      &__img {
        grid-row: 2;
        grid-column: 1;
        background-color: #112E4E;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden; }

      & img {
        opacity: .3;
        width: 100%;
        height: 100%;
        filter: brightness(140%); } }

    &__contact {
      background-image: linear-gradient(#112E4E, #112D4E, #112D4E, #112D4E,#14365B);
      display: grid;
      grid-template-columns: max-content auto;
      align-items: center;
      padding: 4vh 4vw;
      color: #fafafa;

      &__img {
        margin: auto;
        height: 15vmin;
        width: 15vmin; }

      &__wrapper {
        margin-left: 10vw;

        & a {
          color: #ffffff;
          text-decoration: none;
          outline: none;

          &:focus:before,
          &:hover:before {
            pointer-events: none;
            position: absolute;
            z-index: -1;
            content: '';
            visibility: visible;
            animation: fadein 0.5s;
            top: 1vh;
            left: -1.5vw;
            border-width: 0.8vw;
            border-style: solid;
            border-color: transparent transparent transparent white; }

          &:focus:after,
          &:hover:after {
            animation: fadeout 0.5s; } }

        &__item {
          position: relative;
          transform: translateZ(0);

          &__wrapper-img {
            margin-bottom: 3vh;
            position: relative;

            & img {
              height: 5vh;
              width: 5vh;
              margin-right: 1vw;
              vertical-align: middle; } } } } } } }

/* ============== 5.0.- Skills section ============== */

.skills {
  display: grid;
  width: auto;
  height: 100vh;
  background: #FFF; //radial-gradient(#FFFFFF, #FBFBFB)

  & img {
    height: 4vw; }

  &__container {
    width: auto;
    display: grid;
    padding: 4vh 4vw;
    grid-template-rows: min-content auto;

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(6,1fr);
      grid-template-rows: 1fr;
      text-align: center;
      align-self: center;
      color: #000;

      &__item {
        border: 0;
        width: 100%;
        height: 100%;
        font-size: calc(13px + (16 - 13) * (100vw - 400px) / (800 - 400));
        background: transparent;
        border: none;
        padding: 2vh 0;

        &:not(:focus),
        &:not(:hover) {
          background: transparent; }

        &:focus,
        &:hover {
          cursor: pointer;
          background: #f1f1f1;
          // border-radius: 2vw
          outline: none; } } } } }



/* ============== 6.0.- Employee background section ============== */

.employee-background {
  width: auto;
  display: grid;
  grid-template-rows: 50vh 50vh;
  text-align: justify;
  /* background-image: radial-gradient(circle, #112d4e, #102c4c, #102b4a, #0f2a48, #0f2946) */

  &__experience {
    color: white;
    padding: 4vh 4vw;
    display: grid;
    grid-template-rows: min-content 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    background-image: linear-gradient(#112E4E, #112D4E, #112D4E, #112D4E,#14365B);

    &__container {
      display: grid;
      grid-row: 2/3;
      grid-column: 4/-1; }

    &__middle_section {
      text-align: center; }

    &__content {
      text-align: center;
      align-content: center; } }

  &__education {
    color: black;
    padding: 4vh 4vw;
    display: grid;
    grid-template-rows: min-content 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    background: #FFF;

    &__title {
      grid-row: 1/2;
      grid-column: 1/2;
      margin-bottom: 2vh; } }

  &__experience__content,
  &__education__content {
    display: grid;
    text-align: left;
    grid-column: 4/7;
    grid-row: 1/4;
    align-content: center; }

  &__experience__content__title,
  &__education__content__title {
    font-weight: bold; }

  &__experience__content__description,
  &__education__content__description {
    margin-top: 4vh; }

  &__education__wrapper-img, {
    display: grid;
    grid-row: 2/-1;
    grid-column: 1/2; }


  &__education__wrapper-img,
  &__experience__wrapper-img {
    display: grid;
    grid-row: 2/-1;
    grid-column: 1/3; }

  &__education__wrapper-img__img,
  &__experience__wrapper-img__samsung,
  &__experience__wrapper-img__ntt, {
    display: grid;
    width: 100%;
    height: 100%; } }

/* ============== 7.0.- Portfolio section ============== */

.portfolio {
  display: grid;
  width: auto;
  grid-template-rows: 100vh;
  color: white;
  background-image: linear-gradient(#112E4E, #112D4E, #112D4E, #112D4E, #14365B);

  &__container {
    display: grid;
    width: auto;
    padding: 4vh 4vw;
    grid-template-rows: min-content auto;

    &__title {
      margin-bottom: $big-margin-bottom; }

    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      height: 80%;
      grid-gap: 5% 5%;

      &__item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(55,72,108,0.28);
        border: 0;
        outline: 0;

        & img {
          width: 60%; }

        &:hover,
        &:focus, {
          cursor: pointer;
          background-color: rgba(27,88,214,0.17); }

        &__privacy-choices {
          width: 35% !important; } } } } }

//        &__samsung-intranet,
//        &__family-hub,
//        &__profile-explorer
//          width: fit-content

//      & > :first-child
//        grid-area: 1 / 1 / -1 / 2

/* ============== 8.0.- Quizz section  ============== */

.quizz {
  width: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;

  &__container {
    padding: 4vh 4vw;
    background-image: linear-gradient(#112E4E, #112D4E, #112D4E, #112D4E,#14365B);

    &__game {
      display: grid;
      margin: auto;
      background: #FFF;
      width: 50vw;
      height: 40vh;
      border-radius: 15px; }

    &__description {
      margin-bottom: 20vh; } } }

.question {
  background-color: #efefef;
  padding: 0.15vh 4vw 0.15vh 4vw;
  border-radius: 10px;
  vertical-align: middle; }

.questions {
  border-width: 1px;
  border-radius: 10px;
  vertical-align: middle;
  margin-bottom: 1vh; }

.quizz__content {
  padding: 2vh 4vw 2vh 4vw;

  &__nex-question {
    font-size: calc(9px + (14 - 9) * (100vw - 400px) / (800 - 400));
    display: inline-block;
    text-align: right;
    padding: 2vh 4vw 2vh 4vw;

    & button {
      font-size: calc(9px + (14 - 9) * (100vw - 400px) / (800 - 400));
      width: 5vw;
      height: 5vh; } } }

/* ============== 9.0.- Contact section  ============== */

.outro {
  display: grid;
  grid-template-rows: 40vh 60vh;
  text-align: center;

  &__contact-form {
    background-image: $background-blue;
    color: #FFF;
    padding: 4vh 18vw;

    &__title {
      margin-bottom: $big-margin-bottom; }

    &__fields {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: min-content 1fr min-content 1fr;
      grid-gap: 2vh 0.5vw;
      font-size: calc(11px + (15 - 13) * (100vw - 400px) / (800 - 400));

      & label {
        text-align: right;
        display: flex; }

      &__photo-container {
        text-align: left;

        &__input-container {
          display: inline-grid!important;
          border: solid black .1vw;
          padding: 0 2vw 0 .5vw;
          align-content: center;
          cursor: pointer;
          background: white;
          width: max-content;
          text-align: left;
          color: black;
          height: 100%;
          font-size: calc(10px + (10 - 9) * (100vw - 400px) / (800 - 400));
          margin-right: .4vw; } }

      &--required {
        color: #FFF; }

      &__message {
        height: 20vh;
        resize: none;
        font-size: calc(6px + (8.5 - 6) * (100vw - 400px) / (800 - 400)); }

      & input[type="file"] {
        display: none; }

      & input[type="submit"] {
        text-align: center;
        background: white;
        border: solid .5px;
        float: right;
        width: 10vw;
        height: 5vh;
        font-family: 'Poppins', sans-serif;
        font-size: calc(13px + (14 - 13) * (100vw - 400px) / (800 - 400));

        &:hover {
          cursor: pointer; } } }

    & :nth-child(10n) {
      grid-column: 2/5; } }

  &__recruiters {
    padding: 4vh 4vw;
    display: grid;
    grid-template-rows: min-content auto;

    &__title {
      margin-bottom: $big-margin-bottom; }

    &__container {
      display: grid;
      grid-template-rows: 1fr;
      filter: drop-shadow(0px 10px 10px rgba(0,0,0,0.35));
      grid-template-columns: repeat(3,1fr);
      animation: fadeintwo 4.5s;
      font-size: calc(13px + (15 - 13) * (100vw - 400px) / (800 - 400)); } } }

.clip-circle {
  border-radius: 50%;
  height: 12vh;
  width: 6vw; }

.outro__contact-form__fields__submit {
  display: inline-block;
  grid-column: 4/-1;
  width: 100%; }

// .clip-circle
//   background-image: url("https://recruiters-images.s3-us-west-1.amazonaws.com/Jose Cardiel.jpeg")
//   width: 50%
//   border-radius: 50%

//   &:after
//     content: ""
//     padding-bottom: 100%
//     display: block

/* ========== 10.0.- Quizz Section  ========== */

.quizz {
  color: white;

  &__container__game {
    color: #000; }

  &__content__label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    color: #fff;
    background-color:  rgb(17, 45, 78);
    border-style: solid;
    border-radius: 10px;
    border-width: 0px;
    font-weight: normal;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      & :checked ~ .checkmark:after {
        display: block; } }

    &:hover input ~ .checkmark {
      background-color: rgba(255, 255, 255, 0.3); }


    & input:checked ~ .checkmark {
      background-color: rgba(255, 255, 255, 0.3);
      color: black; } } }

.question {
  font-weight: bold; }


.checkmark {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  &:after {
    content: "";
    position: absolute;
    display: none; } }

/* ============== 11.0.- Utilities  ============== */

.fade {
  // -webkit-animation-name: fade
  // -webkit-animation-duration: 2.5s
  animation: fade 2.5s, fade 2.5s; }

/* 11.1.- Toast */

.toast {
  left: 45%;
  height: 3vw;
  color: white;
  background-color: #314b6c;
  position: fixed;
  bottom: 4vh;
  border-radius: .5vw;
  visibility: hidden;
  padding: 0.5vh 2.5vw;

  &--active {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s; }

  &--error {
    background-color: #f44336; }

  &--success {
    background-color: #4caf50; }

  &__container {
    display: inline-grid;
    grid-template-columns: 3vw auto;
    align-content: center;
    height: 100%;

    &__description {
      display: inline-block;
      align-self: center; }

    &__icon {
      height: 50%;
      width: 100%;
      align-self: center; } } }

/* 11.2.- Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: $black;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in;

  &.is-visible {
    visibility: visible;
    opacity: 1; }

  &__dialog__header__close-modal {
      font-size: 1rem;
      position: absolute;
      background: #FFF;
      right: 0;
      width: 6vw;
      border-radius: .4vw .4vw 0 0;
      border: none;
      cursor: pointer; }

  &__dialog__header__close-modal:focus,
  &__dialog__header__close-modal:hover {
    outline: none;

 }    // color: #0034ff

  &__dialog,
  &__portfolio {
    position: relative;
    max-width: 800px;
    max-height: 80vh;
    background: #FFF;
    overflow: auto;
    cursor: default;
    min-width: 50vw;

    & > * {
      padding: 1rem; }

    &__header,
    &__footer {
      background: #fff; }

    &__footer {
      text-align: right; }

    &__content p {
      margin: 0; }

    &__separator {
      padding: 0;
      border: 0.5px solid black; } }

  &__portfolio {
    position: relative;
    background: #FFF;
    cursor: default;
    max-height: 90vh;
    min-height: 50vh;
    min-width: 80vw;
    max-width: 80vw;

    &__close-modal {
      font-size: 1rem;
      position: absolute;
      background: #FFF;
      right: 0;
      width: 6vw;
      border-radius: .4vw .4vw 0 0;
      border: none;
      cursor: pointer; }

    &__close-modal:focus,
    &__close-modal:hover {
      outline: none; }


    &__content {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      min-height: 50vh;
      max-height: 90vh;
      margin: 4vh 0;

      &__slider {
        width: 90%;
        margin: auto; }

      &__info {
        display: grid;
        text-align: center;
        padding: 2vh 2vw;

        &__wrapper {
          align-self: center; }

        &__button {
          margin-top: 5%; }

        &__title {
          font-weight: bold; } } } } }

.open-modal {
  color: black;
  margin-bottom: 1rem;
  // padding: 0.75rem 1.75rem
 }  // border-radius: 5px


/* 11.3.- Slider */

.slider {
  max-width: 100%;
  position: relative;
  margin: auto;
  max-height: 90vh;

  &__slide {
    display: none;
    text-align: center;

    &__img {
      width: auto;
      max-width: 100%;
      max-height: 70vh; }

    &--active {
      display: block; } }

  &__prev-btn,
  &__next-btn {
    cursor: pointer;
    position: absolute;
    padding: 1vh 1vw;
    color: black;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none; }

  &__next-btn {
    right: 0;
    border-radius: 3px 0 0 3px; }

  &__prev-btn:hover, &__next-btn:hover {
    background-color: rgba(0,0,0,0.8);
    color: #FFF; } }

.dots {
  text-align: center;
  margin-top: 2vh;

  &__dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    &--active, &:hover {
      background-color: #717171; } } }

/* 11.4.- Button */
.btn {
  border: none;
  background-color: black;
  color: white;
  font-size: 2vw;
  text-decoration: none;
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 5px 32px;
  border-radius: 92px;
  width: 20vw;
  font-family: 'Poppins', sans-serif;

  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

    & span {
      padding-right: 25px;

      &:after {
        opacity: 1;
        right: 0; } } }

  & span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;

    &:after {
      content: '\21E7';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s; } } }

/* ================ 12.0.- Animations  ================ */

@-webkit-keyframes fade: from opacity:  .4 to opacity: 1;
@keyframes fade: from opacity: .4 to opacity: 1;

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0; }
  to {
    bottom: 4vh;
    opacity: 1; } }

@keyframes fadeintwo {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeout {
  from {
    bottom: 4vh;
    opacity: 1; }
  to {
    bottom: 0;
    opacity: 0; } }

@keyframes fadeouttwo {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

[data-animation] .modal__dialog,
[data-animation] .modal__portfolio {
  opacity: 0;
  transition: all 0.5s $bounceEasing; }

[data-animation].is-visible .modal__dialog,
[data-animation].is-visible .modal__portfolio {
  opacity: 1;
  transition-delay: 0.2s; }

[data-animation="zoomInOut"] .modal__dialog,
[data-animation] .modal__portfolio {
  transform: scale(0.2); }

[data-animation="zoomInOut"].is-visible .modal__dialog,
[data-animation].is-visible .modal__portfolio {
  transform: none; }

/* ================================================================================= */
/* ================================================================================= */
/* ================================================================================= */

@media all and (max-width: 900px) {

  .intro__background {
    background-position: right; }

  .skills__container__wrapper {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3,1fr); }

  .skills img {
    height: 8vh; }

  .skills--title {
    margin-bottom: 25vh; }

  .portfolio__container__wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    & > :first-child {
      grid-area: unset; }

    &__item__privacy-choices, {
      width: 45%; }

    &__item__samsung-intranet,
    &__item__family-hub,
    &__item__profile-explorer {
      width: 75%; } }

  .modal__portfolio__content {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    height: 80vh;

    &__slider {
      grid-row: 1/2; } }

  .slider img {
    height: 35vh;
    width: 100%; }

  .outro__contact-form {
    padding: 4vh 10vw; }

  .clip-circle {
    border-radius: 50%;
    height: 12vh;
    width: 10vw; }


  // .clip-circle
  //   border-radius: 50%
  //   height: 50%
 }  //   width: 50%

@media all and (max-width: 600px) {   // Mobile portrait

  html, body {
    font-size: calc(16px + (16 - 13) * (100vw - 400px) / (800 - 400)); }

  .intro__background {
    background-position: right; }

  .intro__text-container {
    bottom: 2em; }

  .about-me__container__contact__img {
    display: none; }

  .about-me__container__summary__description {
    margin-top: 2vh; }

  .about-me__container__summary {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr 1fr;

    &__img {
      grid-row: 2;
      grid-column: 1/-1;
      width: 100%;
      height: 100%; }

    &__description {
      grid-row: 3;
      grid-column: 1/-1;
      align-self: normal; } }

  .about-me__container__contact__wrapper__item img {
    margin-right: 2vw; }

  .skills__container__wrapper {
    grid-template-rows: repeat(6,1fr);
    grid-template-columns: 1fr;
    color: black;
    align-items: center;
    // grid-row-gap: 2vh
    text-align: left;

    & img {
      text-align: right;
      height: 10vmin;
      width: 10vmin; }

    &__item {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: min-content 1fr;
      grid-column-gap: 5vw;
      align-items: center;
      transform: unset;
      transition: unset;
      text-align: left;

      &:hover,&:focus {
        background: transparent; } } }

  .employee-background {
    grid-template-rows: 50vh 50vh;
    grid-template-columns: 1fr;
    background: none;

    &__experience__content__description,
    &__education__content__description {
      margin-top: 2vh; }

    &__education__wrapper-img__img {
      opacity: 0.2;
      width: 50%;
      height: 30%;
      position: absolute;
      right: 0;
      bottom: 0px; }

    &__experience__wrapper-img__samsung {
      opacity: 0.2;
      width: 35%;
      height: 30%;
      position: absolute;
      right: 0;
      bottom: 0px;
      padding: 10px; }

    &__experience__wrapper-img__ntt {
      opacity: 0.2;
      width: 35%;
      height: 30%;
      position: absolute;
      left: 0;
      bottom: 0px;
      padding: 10px; }

    &__experience {
      grid-column: 1;
      grid-row: 1;
      padding: 4vh 4vw;
      background-image: linear-gradient(#112E4E, #112D4E, #112D4E, #112D4E, #14365B);
      grid-template-columns: 1fr;
      grid-template-rows: min-content auto;
      position: relative;

      &__container {
        display: grid;
        grid-row: 2/3;
        grid-column: 1/-1; } }

    &__education {
      grid-template-columns: 1fr;
      grid-template-rows: min-content auto; }

    &__experience__content,
    &__education__content {
      grid-column: 1/-1;
      grid-row: 2/-1;
      text-align: left;
      display: unset; }

    &__education {
        grid-column: 1;
        grid-row: 2;
        padding: 4vh 4vw; } }

  .portfolio__container__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6,1fr);
    // grid-gap: 5% 5%

    &__item__privacy-choices {
      width: 15% !important;
      height: 50% !important; }

    &__item__samsung-intranet,
    &__item__family-hub,
    &__item__profile-explorer,
    &__item__my-resume {
      width: 75%; }

    & img {
      height: 75%;
      width: 20%; }

    & > :first-child,
    & :nth-child(2) {
      height: 100%; }

    & * {
      height: 100%; } }

  .about-me__container__contact__wrapper__item a:hover:before {
    visibility: hidden; }

  .quizz__container__game {
    width: 75vw;
    height: 50vh;
    font-size: 1.5rem; }

  .quizz__content__label {
    border-style: solid;
    border-radius: 30px;
    border-color: white;
    border-width: 1px; }

  .quizz__content__nex-question,
  .quizz__content__nex-question button {
    font-size: 1.5rem; }

  .quizz__content__nex-question button {
    width: 14vw;
    height: 6vh; }

  .quizz__container__description {
    margin-bottom: 5vh; }

  .checkmark {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 50%; }

  .modal__portfolio__content {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    height: 80vh;

    &__slider {
      grid-row:1/2 {} } }

  .outro {
    width: auto;
    height: 100vh;
    background: #FFF;
    color: black;

    &__contact-form {
      display: grid;
      grid-template-rows: min-content 100%;
      grid-template-columns: 1fr;
      background-image: unset;
      color: black;

      &__fields {
        grid-template-rows: repeat(12,1fr);
        grid-template-columns: 1fr;

        &--required {
          color: red; } }

      & label {
        text-align: left; }

      &__title {
        font-size: calc(22px + (30 - 22) * (100vw - 400px) / (800 - 400));
        text-align: left; } }

    &__recruiters {
      display: none; } }

  .outro__contact-form__fields :nth-child(10n),
  .outro__contact-form__fields__submit {
    grid-column: unset; }



  .outro__contact-form input[type="submit"] {
    border: solid .5px;
    width: 30vw;
    font-size: calc(13px + (16 - 13) * (100vw - 400px) / (800 - 400)); }

  .toast {
    bottom: 0;
    left: 0;
    border-radius: 5%;
    width: 100%;
    height: 5vh;
    text-align: center;

    &--active {
      animation: fadeintwo 0.5s, fadeouttwo 0.5s 2.5s; }

    &__container {
      display: flex;
      justify-content: center;

      &__description {
        font-size: calc(13px + (24 - 13) * (100vw - 400px) / (800 - 400)); }

      &__icon {
        height: 70%;
        width: 10%; } } }

  .outro__contact-form__fields__message {
    font-family: 'Poppins', sans-serif;
    font-size: calc(16px + (16 - 13) * (100vw - 400px) / (800 - 400)); } }



@media all and (max-width: 900px) and (max-height: 500px) {   // Mobile landscape
  .portfolio__container__wrapper__item img {
    width: 20vw;
    height: 30vh; }

  .outro {
    grid-template-rows: 100vh; }

  .outro__recruiters {
    display: none; }

  .outro {
    width: auto;
    height: 100vh;
    background: #FFF;
    color: black;

    &__contact-form {
      display: grid;
      grid-template-rows: min-content 100%;
      grid-template-columns: 1fr;
      background-image: unset;
      color: black;

      &__fields {
        grid-template-rows: repeat(8, 1fr);
        grid-template-columns: min-content auto;

        & input[type="submit"] {
          width: min-content; }

        &--required {
          color: red; } }

      & label {
        text-align: left; }

      &__title {
        font-size: calc(22px + (30 - 22) * (100vw - 400px) / (800 - 400));
        text-align: left; } }

    &__recruiters {
      display: none; } }

  .outro__contact-form__fields :nth-child(10n),
  .outro__contact-form__fields__submit {
    grid-column: unset; }

  .outro__contact-form__fields__submit {
    grid-column: 2/-1; } }

